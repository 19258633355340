/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-13 14:40:14
 * @Module: 用户页面
 */
 <template>
  <div style="background:#f7f7f7;min-height:100vh; "
       v-if="profileData">
    <div class="user-box">
      <div style="height:60px;"></div>
      <div class="user-header">
        <img src="@/static/homepage/user_bg.png"
             alt=""
             class="user-header-t" />
        <!-- <div class="user-header-t"></div> -->
        <div class="user-header-b">
          <avatar-update :size="100"
                         class="user-header-b-avatar"
                         :src="profileData.user.avatar"
                         v-if="pageName == 'profileEdit'" />
          <el-avatar :size="100"
                     class="user-header-b-avatar"
                     :src="profileData.user.avatar"
                     v-else />
          <div class="user-header-b-r"
               v-if="pageName == 'profileEdit'">
            <div class="user-header-b-r-username">
              {{ profileData.user.nick_name }}
            </div>
            <div class="user-header-b-r-btns hover">
              <div class="user-header-b-r-btns-edit"
                   v-if="profileData.is_myself"
                   @click="$router.go(-1)">
                返回
              </div>
            </div>
          </div>

          <div class="user-header-b-r"
               v-else>
            <div class="user-header-b-r-username">
              {{ profileData.user.nick_name }}
            </div>
            <div class="user-header-b-r-btns hover">
              <div class="user-header-b-r-btns-follow"
                   v-if="!profileData.is_myself && !profileData.has_followed"
                   @click="follow">
                +关注
              </div>
              <div class="user-header-b-r-btns-unfollow"
                   v-if="!profileData.is_myself && profileData.has_followed"
                   @click="unfollow">
                取消关注
              </div>
              <!-- <div class="user-header-b-r-btns-follow"
                   v-if="!profileData.is_myself"
                   @click="chatClick">
                私信
              </div> -->
              <div class="user-header-b-r-btns-follow"
                   v-if="
                  !profileData.is_myself && profileData.user.can_accept_anchor
                "
                   @click="goTj">
                向Ta推荐主播
              </div>
              <div class="user-header-b-r-btns-edit"
                   v-if="profileData.is_myself"
                   @click="editClick">
                编辑个人资料
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-container class="user-content">
        <el-main width="884px"
                 class="user-contentt-l"
                 style="padding:0px;">
          <router-view />
        </el-main>
        <el-aside width="340px"
                  class="user-content-r">
          <follow-info :data="profileData.counter" />
          <topup />
          <attention />
        </el-aside>
      </el-container>
    </div>
  </div>
</template>
 <script>
import topup from "@/views/homepage/rightmodule/topup";
import attention from "@/views/homepage/rightmodule/attention";
import followInfo from "./module/followInfo";
import avatarUpdate from "@/views/homepage/user/module/avatarUpdate";
import { Loading } from "element-ui";
export default {
  components: {
    topup,
    attention,
    followInfo,
    avatarUpdate
  },
  data () {
    return {
      loading: false,
      profileData: null,
      pageName: null
    };
  },
  mounted () {
    this._onload({ uuid: this.$route.query.uuid });
  },
  methods: {
    goTj () {
      console.log(this.$route.query.uuid)
      this.$router.push({
        path: "/star/toBroker",
        query: {
          broker_id: this.$route.query.uuid
        }
      });
    },
    async _onload ({ uuid }) {
      let loading = Loading.service({ fullscreen: true });
      await this._get_profile(uuid);
      loading.close();
    },
    async _get_profile (uuid) {
      const { data, status, info } = await this.$api.profileApi({ uuid });
      if (status == 200) {
        if (data.is_myself) {
          this.$store.commit("user/setUserInfo", {
            following: data.counter.following,
            follower: data.counter.follower,
            published_count: data.counter.published_count
          });
        }
        this.$set(this, "profileData", data);
      } else {
        this.$message.error(info);
      }
    },
    // 关注
    async _get_follow () {
      const user_id = this.$route.query.uuid;
      const { status, info } = await this.$api.followApi({ user_id });
      if (status == 200) {
        this.$message.success(info);
      } else {
        this.$message.error(info);
        this.profileData.has_followed = 0;
      }
    },
    // 取消关注
    async _get_unfollowApi () {
      const user_id = this.$route.query.uuid;
      const { status, info } = await this.$api.unfollowApi({ user_id });
      if (status == 200) {
        this.$message.success(info);
      } else {
        this.$message.error(info);
        this.profileData.has_followed = 1;
      }
    },
    follow () {
      this.profileData.has_followed = 1;
      this._get_follow();
    },
    unfollow () {
      this.profileData.has_followed = 0;
      this._get_unfollowApi();
    },
    editClick () {
      this.$store.dispatch("homepage/toProfileEdit", {
        uuid: this.$route.query.uuid
      });
    },
    chatClick () {
      this.$router.push(`/homepage/chat/${this.$route.query.uuid}`)
    }
  },
  watch: {
    $route: {
      handler: function ({ name }) {
        this.pageName = name;
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  }
};
</script>
 <style lang='scss' scoped>
.user-box {
  width: 1240px;
  margin: 0 auto;
}
.user-header {
  margin-top: 16px;
  height: 244px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  &-t {
    height: 160px;
    background: #000;
  }
  &-b {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    &-avatar {
      margin-top: -35px;
    }
    &-r {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 12px;
      margin-top: 9px;
      &-username {
        font-size: 26px;
        color: #333;
      }
      &-btns {
        display: flex;
        align-items: center;
        &-follow,
        &-edit,
        &-unfollow {
          padding: 0 15px;
          height: 34px;
          line-height: 34px;
          background: #df2a29;
          border-radius: 2px;
          text-align: center;
          font-size: 14px;
          color: #ffffff;
          margin-left: 20px;
        }
        &-edit,
        &-unfollow {
          background: #fff;
          color: #df2a29;
          border: 1px solid #df2a29;
        }
      }
    }
  }
}
.user-content {
  margin-top: 16px;
  &-l {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  }
  &-r {
    margin-left: 16px;
  }
}
</style>