/* 
 * @Author: 曹俊杰 
 * @Date: 2020-10-31 16:12:41
 * @Module: 充值
 */
 <template>
  <img src="@/static/homepage/vip_banner.png"
       mode="aspectFill"
       class="topup-box hover"
       @click="click" />
</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    click () {
      this.$message({
        message: '请前往APP下载页下载手机端网红头条查看',
        center: true
      });
    }
  },
};
 </script>
 <style lang='scss' scoped>
.topup-box {
  width: 100%;
  height: 56px;
  background: #000;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 16px;
}
</style>