/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-18 17:17:44
 * @Module: 头像上传
 */
 <template>
  <div>
    <el-upload action="/api/user/upload-avatar"
               :headers="{
			Authorization:'Bearer ' + $store.state.user.userInfo.token
		}"
               name="avatar"
               :show-file-list="false"
               :multiple="false"
               :on-success="success"
               :before-upload="upload"
               accept="image/*">
      <el-avatar :size="size"
                 :src="src">
      </el-avatar>
    </el-upload>
  </div>
</template>
 <script>
import { Loading } from 'element-ui';
export default {
  props: {
    size: {
      type: Number,
      default: 100
    },
    src: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      loading: null
    };
  },
  mounted () { },
  methods: {
    upload () {
      this.loading = Loading.service({ fullscreen: true });
    },
    success ({ status, info, data: { address: { relative } } }) {
      if (status == 200) {
        this._set_avatar(relative)
      } else {
        this.$message.error(info)
        this.loading.close();
      }
    },
    async _set_avatar (value) {
      const { status, info } = await this.$api.infoAvatarUpdateApi({ value });
      if (status == 200) {
        this.$router.go(0)
      } else {
        this.$message.error(info)
        this.loading.close();
      }
    },
  },
};
 </script>
 <style lang='scss' scoped>
</style>